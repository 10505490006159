.logo {
    display: flex;
    column-gap: 14px;
    align-items: center;

    &__icon {
        width: 3rem;
        height: 3rem;
        color: #EF6D58;
    }

    &__text {
        font-family: arial;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2rem;
    }
}