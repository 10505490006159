$accent: #EF6D58;

.text {
    font-size: 1rem;
    line-height: 2rem;
}

.h4 {
    font-family: arial;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
}

a {
    color: inherit;
    text-decoration: none;

    &:focus {
        outline: none;
    }
}

.header {
    background-color: #28293E;
    color: white;
}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.header__content {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        padding: 0.5rem 0;
    }
}

.logo {
    display: flex;
    column-gap: 14px;
    align-items: center;
}

.logo__icon {
    width: 3rem;
    height: 3rem;
    color: $accent;
}

.logo__text {
    @extend .h4;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 80px;

    @media (max-width: 991px) {
        column-gap: 35px;
    }

    @media (max-width: 767px) {
        position: fixed;
        top: 64px;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: #28293E;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 15px;

        z-index: 10;
        transform: translateX(100%);
        transition: 0.5s;

        &--open {
            transform: translateX(0);
        }
    }
}

.nav-bar__btn {
    @extend .text;
    padding: 0 10px;
    border-bottom: 2px solid transparent;
    transition: 0.3s;

    @media (max-width: 767px) {
        font-size: 24px;
    }

    &:visited {};

    &:hover {
        color: $accent;
    }

    &:focus {
        border-bottom: 2px solid ; 
    }

    &:active {
        color: $accent;
        border-color: $accent;
    }
}

.contact-btn {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    outline: 1px solid rgba(255,255,255, 0.3);
    padding: 0 2.19rem;
    color: white;
    background-color: transparent;
    border-radius: 7px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: $accent;
        outline-color: $accent;
    }

    &:focus {
        box-shadow: 0 0 8px 3px white inset;
    }

    &:active {
        box-shadow: 0 0 8px 3px $accent inset;
    }
}

.burger-menu {
    width: 48px;
    height: 48px;
    border: 1px solid white;
    border-radius: 6px;

    svg {
        color: white;
        width: 24px;
        height: 24px;
    }

    &:hover {
        border-color: $accent;
    }

    &:focus {
        box-shadow: 0 0 5px 1px white inset;
    }

    &:active {
        svg {
            color: $accent;
        }
    }
}