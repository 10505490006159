$text-dark: rgb(56, 20, 0);
$text-dark-gray: rgba(57, 20, 0, 0.64);
$accent: #EF6D58;

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    
    @media (max-width: 1199px) {
        padding: 0 80px;        
    }
    
    @media (max-width: 767px) {
        max-width: 470px;
        padding: 0 23px;        
    }
}

.title {
    background-color: #28293E;
    color: white;
    padding: 84px 0 66px 0;

    @media (max-width: 1439px) {
        padding: 24px 0 40px 0;
    }

    @media (max-width: 991px) {
        padding: 10px 0 20px 0;
    }
}

.title__inner {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    @media (max-width: 991px) {
        row-gap: 0.5rem;
    }
}

.h1 {
    font-size: 4.5rem;
    font-weight: bolder;
    line-height: 1.25;

    @media (max-width: 1439px) {
        font-size: 3.5rem;
    }
}

.text {
    font-size: 1rem;
    line-height: 2;
    
    @media (max-width: 991px) {
        line-height: 1.5
    }
}

.text-small {
    font-size: 0.88rem;
    line-height: 1.7;
}

.main-title {
    text-align: center;
    color: white;
}

.sub-title {
    font-weight: 400;
    text-align: center;
    color: lightgray;
}

.content {
    min-height: calc(100vh - 400px);
    background-color: #FDF0E9;
    padding: 68px 0;

    section + section {
        padding-top: 112px;

        @media (max-width: 1439px) {
            padding-top: 60px;
        }

        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    @media (max-width: 1439px) {
        padding: 38px 0;
    }
}