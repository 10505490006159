$accent: #EF6D58;

.text {
    font-size: 1rem;
    line-height: 2rem;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 80px;

    @media (max-width: 991px) {
        column-gap: 35px;
    }

    &--mobile {
        flex-direction: column;
        row-gap: 35px
    }
}

.nav-bar__btn {
    @extend .text;
    padding: 0 10px;
    border-bottom: 2px solid transparent;
    transition: 0.3s;

    @media (max-width: 767px) {
        font-size: 24px;
    }

    &:visited {};

    &:hover {
        color: $accent;
    }

    &:focus {
        border-bottom: 2px solid ; 
    }

    &:active {
        color: $accent;
        border-color: $accent;
    }
}