$text-dark: rgb(56, 20, 0);
$text-dark-gray: rgba(57, 20, 0, 0.64);
$accent: #EF6D58;

.text {
    font-size: 1rem;
    line-height: 2;
    
    @media (max-width: 991px) {
        line-height: 1.5
    }
}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    
    @media (max-width: 1199px) {
        padding: 0 80px;        
    }
    
    @media (max-width: 767px) {
        max-width: 470px;
        padding: 0 23px;        
    }
}

.about {}

.about-inner {
    padding: 41px 100px 71px;
    background-color: $accent;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1439px) {
        padding: 50px;
    }

    @media (max-width: 991px) {
        padding: 25px;
    }

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        row-gap: 37px;
        padding: 32px 28px;
    }
}

.about__left {
    padding-top: 55px;
    flex-grow: 1;
    max-width: 48%;
    color: white;

    .h2 {
        margin-bottom: 0.5em;
        max-width: 13ch;
    }

    p {
        margin-bottom: 34px;
        @extend .text;
        opacity: 0.64;
        line-height: 2;
    }
    
    @media (max-width: 991px) {
        padding-top: 30px;
    }

    @media (max-width: 767px) {
        max-width: 100%;
        padding-top: 0;

        p {
            margin-bottom: 21px;
        }
    }
}

.h2 {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.14;
    letter-spacing: -1px;

    @media (max-width: 1439px) {
        font-size: 48px;
    }

    @media (max-width: 1199px) {
        font-size: 44px;  
    }

    @media (max-width: 991px) {
        font-size: 38px;  
    }

    @media (max-width: 767px) {
        font-size: 40px;
        font-weight: 700;  
    }
}

.about__right {
    position: relative;
    max-width: 430px;
    width: 44%;
    aspect-ratio: 1;
    flex-shrink: 0;

    @media (max-width: 991px) {
        width: 46%;   
    }
    
    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
}

.btn {
    display: inline-block;
    padding: 17px 43px;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1;
    background-color: #fff;
    color: #391400;
    border-radius: 6px;
    transition: 0.3s;
    box-shadow: 0 3px 9px 0 rgba(57, 20, 0, 0.08);

    @media (max-width: 991px) {
        padding: 17px 26px;
    }

    &:hover {
        box-shadow: 0 3px 9px 0 $accent;
    }

    &--special:hover {
        box-shadow: 0 3px 9px 0 #391400;
    }

    &:focus {
        outline: 2px solid $text-dark;
    }

    &:active {
        transform: translateY(5%);
        background-color: $accent;
        color: white;
        outline: none;
    }

    &--special:active {
        outline: 2px solid white;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rect {
    position: absolute;
    left: 3.3%;
    bottom: 24.2%;
    z-index: 3;
    width: 49.8%;
    height: 26%;
}

.img-star {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 46.5%;
    height: 51.2%;
}

.img-circle {
    position: absolute;
    bottom: 0;
    left: 16.3%;
    z-index: 1;

    width: 74%;
    aspect-ratio: 1;

    animation-name: rotate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.img-photo {
    position: absolute;
    right: 0;
    bottom: 16%;
    background-color: #F7CC47;
    border-radius: 200px 200px 14px 14px;
    overflow: hidden;
    z-index: 2;

    width: 57.2%;
    height: 70.9%;

    img {
        transform: translateY(11%)
    }
}

@keyframes rotate {
    to{
        transform: rotate(-360deg);
    }
}
