.mobile-menu {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 35px 15px;

    background-color: #28293E;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 10;
    transform: translateX(100%);
    transition: 0.5s;

    &--open {
        transform: translateX(0);
    }
}